var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-pwd-box"},[_c('van-nav-bar',{attrs:{"title":"修改密码","left-text":"返回","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"label":"手机号","clearable":"","disabled":"","required":"","maxlength":"11","placeholder":"输入手机号","rules":[{ required: true }]},model:{value:(_vm.userForm.mobile),callback:function ($$v) {_vm.$set(_vm.userForm, "mobile", $$v)},expression:"userForm.mobile"}})],1),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"type":"password","label":"密码","clearable":"","required":"","maxlength":"16","placeholder":"设置6~16位数密码,注意区分大小写","rules":[
          { required: true },
          {
            validator: _vm.validatePwdLen,
            message: '长度在 6 到 16 个字符',
            trigger: 'onBlur',
          },
        ]},model:{value:(_vm.userForm.password),callback:function ($$v) {_vm.$set(_vm.userForm, "password", $$v)},expression:"userForm.password"}})],1),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"type":"password","label":"确认密码","clearable":"","required":"","maxlength":"16","placeholder":"再次输入密码","rules":[
            { required: true },
            {
              validator: _vm.validatePwdLen,
              message: '长度在 6 到 16 个字符',
              trigger: 'onBlur',
            },
            {
              validator: _vm.validateRepeatPassword,
              message: '两次输入密码不一致',
              trigger: 'onBlur',
            },
          ]},model:{value:(_vm.userForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.userForm, "repeatPassword", $$v)},expression:"userForm.repeatPassword"}})],1),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"label":"短信验证码","clearable":"","required":"","placeholder":"输入短信验证码","maxlength":6,"rules":[{ required: true }]},model:{value:(_vm.userForm.smsCaptcha),callback:function ($$v) {_vm.$set(_vm.userForm, "smsCaptcha", $$v)},expression:"userForm.smsCaptcha"}}),_c('span',{class:{ 'no-style': _vm.intervalId },on:{"click":_vm.sendPhoneVerifyRegist}},[_vm._v(_vm._s(_vm.sendNotice))])],1),_c('div',{staticClass:"filed-box"},[_c('van-field',{attrs:{"label":"图形验证码","clearable":"","required":"","placeholder":"输入图形验证码","rules":[{ required: true }]},model:{value:(_vm.userForm.imageCaptcha),callback:function ($$v) {_vm.$set(_vm.userForm, "imageCaptcha", $$v)},expression:"userForm.imageCaptcha"}}),_c('span',[(_vm.imgVisible)?_c('img',{attrs:{"src":_vm.imageCaptchaUrl},on:{"click":function($event){return _vm.init()}}}):_vm._e()])],1),_c('div',{staticClass:"btn-box"},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }